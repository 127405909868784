import React, { useEffect, useState, useMemo } from "react";
import styled, { keyframes } from "styled-components";

const StyledButton = styled.a`
  padding: 0.75rem;
  width: calc(100% - 1.5rem - 2.5rem);
  background-color: #00b391;
  border-radius: 0.125rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
`;

const DoneButton = styled(StyledButton)`
  color: #00b391;
  background-color: #fff;
`;

const StyledBlockedButton = styled(StyledButton)`
  cursor: not-allowed;
  background-color: rgb(4, 17, 42);
  height: calc(1rem + 1px);
  border: 0.5px solid #00b391;
`;

const LoadingAnimation = styled.span`
  &:after {
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    margin: auto;
    border: 0.125rem solid transparent;
    border-top-color: #00b391;
    border-radius: 75%;
    animation: button-spinner 1s ease infinite;
  }
`;

export const Button: React.FC<{
  text: string;
  onClick?: () => void;
  isLoading?: boolean;
  isDone?: (() => void) | boolean;
}> = ({ text, onClick }) => {
  const [btnState, setBtnState] = useState<number>(3);

  const customBtn = useMemo(() => {
    switch (btnState) {
      case 1:
        return (
          <StyledBlockedButton>
            <LoadingAnimation>
              <span style={{ visibility: "hidden" }}>I</span>
            </LoadingAnimation>
          </StyledBlockedButton>
        );

      case 2:
        return <DoneButton>{text}</DoneButton>;

      case 3:
        return <StyledButton onClick={internalOnClick}>{text}</StyledButton>;
    }
  }, [btnState]);

  async function internalOnClick() {
    setBtnState(1);

    if (onClick !== undefined) {
      await onClick();
      setBtnState(3);
    } else {
      setBtnState(3);
    }
  }

  return <>{customBtn}</>;
};

Button.defaultProps = {
  isLoading: false,
};
