import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ProductSpecs,
  ProductProps,
} from "../../components/layout/ProductSpecs/ProductSpecs";
import { Button } from "../../components/layout/Button/Button";
import GoogleMapReact from "google-map-react";
import { useLocation, useParams } from "react-router";
import { Marker } from "../../components/layout/Marker/Marker";
import { api } from "../../api";
import axios from "axios";
import { waitFor } from "@testing-library/dom";
import { idText } from "typescript";
import {ActivityStatus} from '../../common';

export const Product: React.FC = () => {
  const { id: productId } = useParams<{ id: string }>();

  const url = useLocation();

  const [product, setProduct] = useState<ProductProps | null>(null);
  const [pos, setPos] = useState<GeolocationPosition | null>(null);
  //let [markers, setMarkers] = useState<any[]>([]);
  const [mapOptions, setMapOptions] = useState<any>();
  const [markers, setMarkers] = useState<JSX.Element[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const params = new URLSearchParams(url.search);
    const batch = params.get("batch");
    const lot = params.get("lot");

    if (productId !== undefined) {
      api
        .get<ProductProps>(`/products/${productId}`)
        .then((response) => {
          setProduct(response.data);
        })
        .catch(console.error);
    } else if (batch !== undefined && lot !== undefined) {
      api
        .get<ProductProps>(`/products/?batch=${batch}&lot=${lot}`)
        .then((response) => {
          setProduct(response.data);
        })
        .catch(console.error);
    }
  }, [productId, url]);

  useEffect(() => {
    if (pos !== undefined && pos !== null) {
      setMarkers([
        <Marker key={index} lng={pos.coords.longitude} lat={pos.coords.latitude} />,
      ]);
      setIndex(index + 1);

      if (mapOptions !== undefined) {
        mapOptions.map.setCenter({
          lng: pos.coords.longitude,
          lat: pos.coords.latitude,
        });
      }
    }
  }, [pos]);

  function initGmaps(map: any, maps: any) {
    setMapOptions({ map, maps });

    navigator.geolocation.getCurrentPosition(updatePosition,
      (err) => () => null,
      { enableHighAccuracy: true, timeout: 1000 }
    );

    navigator.geolocation.watchPosition(updatePosition,
      (err) => () => null,
      { enableHighAccuracy: true, timeout: 1000 }
    );
  }

  const updatePosition = (coords: GeolocationPosition | null) => {
    if (coords) {
      setPos(coords);
    }
  }

  const postLocationWithActivity = useCallback(
    async (activityId: ActivityStatus) => {
      if (pos) {
        let id = productId;

        if (id === undefined && product)
          id = product.productId + "";
        await api
          .post(`/products/${id}/locations`, {
            longitude: pos.coords.longitude,
            latitude: pos.coords.latitude,
            activityId,
          })
          .then(r => console.log(`OK! => ${r}`))
          .catch(console.error);
      }
    }, [pos]
  );

  return (
    <div className="content">
      <section style={{ marginBottom: "1rem" }}>
        <ProductSpecs {...product} />
      </section>

      <section className="google-maps">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
          }}
          defaultCenter={{ lat: 0, lng: 0 }}
          defaultZoom={18}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => initGmaps(map, maps)}
        >
          {markers}
        </GoogleMapReact>
      </section>

      <section
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          backgroundColor: "#04112a",
          padding: "2rem 0",
        }}
      >
        <Button text="Track" onClick={() => postLocationWithActivity(ActivityStatus.TRACK)} />
        <Button text="Fill" onClick={() => postLocationWithActivity(ActivityStatus.FILL)} />
        <Button text="Load/Ship" onClick={() => postLocationWithActivity(ActivityStatus.LOAD_SHIP)} />
        <Button text="Receive" onClick={() => postLocationWithActivity(ActivityStatus.RECEIVE)} />
        <Button text="Empty" onClick={() => postLocationWithActivity(ActivityStatus.EMPTY)} />
      </section>
    </div>
  );
};
