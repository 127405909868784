import React from "react";
import styled from "styled-components";

const Loader = styled.div`
  width: 125px;
  background-color: #e9e9e9;
  height: 1rem;
  border-radius: 0.5em;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0) 70%,
      rgba(255, 255, 255, 0) 100%
    );
    animation: animation 1s ease infinite;
  }
`;

export const Skeleton: React.FC<{ isLoading: boolean; children?: any }> = ({
  isLoading,
  children,
}) => {
  return <>{isLoading ? <Loader /> : children}</>;
};
