import React from "react";
import markerImg from "../../../gmaps-marker.png";
import styled from "styled-components";

const MarkerElement = styled.img`
  position: absolute;
  top: -24px;
  left: -12px;
  width: 24px;
  heigth: 24px;
`;

export const Marker: React.FC<{ lng: number; lat: number }> = ({
  lng,
  lat,
}) => <MarkerElement src={markerImg} />;
