import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { formatToYYDDMM } from "../../../utils/dateConverter";
import { Skeleton } from "../Skeleton/Skeleton";

export interface ProductProps {
  productId?: number;
  productName?: string;
  brand?: string;
  productCode?: string;
  ibcNumber?: string;
  lot?: string;
  productionDate?: Date;
  expirationDate?: Date;
  weight?: number;
}
let Article = styled.article``;
let Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.2em 1em;
  border-bottom: 0.5px solid #f7f7f7;
`;

let Title = styled.span`
  font-weight: 500;
  letter-spacing: 0.025rem;
`;

let ValueText = styled.span``;

export const ProductSpecs: React.FC<ProductProps> = (product) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (
      product !== undefined &&
      product !== null &&
      product.productId !== undefined
    ) {
      setIsLoading(false);
    }
  }, [product]);

  return (
    <>
      {!isLoading ? (
        <Article>
          <Row>
            <Title>Product:</Title>
            <ValueText>{product.productName}</ValueText>
          </Row>
          <Row>
            <Title>Brand:</Title> <ValueText>{product.brand}</ValueText>
          </Row>
          <Row>
            <Title>Product Code:</Title>{" "}
            <ValueText>{product.productCode}</ValueText>
          </Row>
          <Row>
            <Title>Batch (lot):</Title> <ValueText>{product.lot}</ValueText>
          </Row>
          <Row>
            <Title>IBC no:</Title> <ValueText>{product.ibcNumber}</ValueText>
          </Row>
          <Row>
            <Title>Production Date:</Title>{" "}
            <ValueText>{formatToYYDDMM(product.productionDate)}</ValueText>
          </Row>
          <Row>
            <Title>Expiration Date:</Title>{" "}
            <ValueText>{formatToYYDDMM(product.expirationDate)}</ValueText>
          </Row>
          <Row>
            <Title>Net Weight:</Title>{" "}
            <ValueText>{product.weight} kg</ValueText>
          </Row>
        </Article>
      ) : (
        <Article>
          <Row>
            <Title>
              <Skeleton isLoading={isLoading} />
            </Title>
            <ValueText>
              <Skeleton isLoading={isLoading} />
            </ValueText>
          </Row>
          <Row>
            <Title>
              <Skeleton isLoading={isLoading} />
            </Title>{" "}
            <ValueText>
              <Skeleton isLoading={isLoading} />
            </ValueText>
          </Row>
          <Row>
            <Title>
              <Skeleton isLoading={isLoading} />
            </Title>{" "}
            <ValueText>
              <Skeleton isLoading={isLoading} />
            </ValueText>
          </Row>
          <Row>
            <Title>
              <Skeleton isLoading={isLoading} />
            </Title>{" "}
            <ValueText>
              <Skeleton isLoading={isLoading} />
            </ValueText>
          </Row>
          <Row>
            <Title>
              <Skeleton isLoading={isLoading} />
            </Title>{" "}
            <ValueText>
              <Skeleton isLoading={isLoading} />
            </ValueText>
          </Row>
          <Row>
            <Title>
              <Skeleton isLoading={isLoading} />
            </Title>{" "}
            <ValueText>
              <Skeleton isLoading={isLoading}>
                <Skeleton isLoading={isLoading} />
              </Skeleton>
            </ValueText>
          </Row>
          <Row>
            <Title>
              <Skeleton isLoading={isLoading} />
            </Title>{" "}
            <ValueText>
              <Skeleton isLoading={isLoading} />
            </ValueText>
          </Row>
          <Row>
            <Title>
              <Skeleton isLoading={isLoading} />
            </Title>{" "}
            <ValueText>
              <Skeleton isLoading={isLoading} />
            </ValueText>
          </Row>
        </Article>
      )}
    </>
  );
};
