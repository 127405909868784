import logo from "./logo.svg";
import { Product } from "./containers/Product/Product";

import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <header className="header-logo">
        <img src={logo} alt="Logo" />
      </header>

      <BrowserRouter>
        <Switch>
          <Route path="/products/:id" render={() => <Product />} />
          <Route path="/test" render={() => <div> Hello :)</div>} />
          <Route path="/" render={() => <Product />} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
