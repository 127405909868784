export const formatToYYDDMM = (date: Date | undefined): string => {
  if (date === undefined || date === null) return "";
  date = new Date(date);

  return date.toLocaleDateString("no-nb", {
    day: "2-digit",
    month: "numeric",
    year: "2-digit",
  });
};
